<template>
  <header id="top-nav">
    <div class="navbar-custom">
      <ul class="topnav-menu top-dropdown-admin-list pm-0">
        <li class="">
          <label class="pointer nav-link" data-toggle="collapse" data-target="#settings-menu" title="الإعدادات">
            <settings-icon></settings-icon>
          </label>
          <div class="collapse" id="settings-menu" data-parent="#top-nav">
            <div class="links">
              <router-link :to="{name: 'admin.profile'}" class="dropdown-item"><strong>بياناتي</strong></router-link>
              <hr class="pm-0">
              <router-link class="dropdown-item" :to="{name: 'auctions.index'}">
                <span>الرئيسية</span>
              </router-link>
              <router-link class="dropdown-item" :to="{name: 'admin.dashboard'}">
                <span>لوحة التحكم</span>
              </router-link>
              <router-link class="dropdown-item" :to="{name: 'change.password'}">
                <span>كلمة المرور</span>
              </router-link>
              <modal-btn btn-class="dropdown-item rounded-0" target="#confirm_sign_out">
                <span>تسجيل الخروج</span>
              </modal-btn>
            </div>
          </div>
        </li>
        <li class="dropdown">
          <a class="navbar-toggle nav-link" data-toggle="collapse" data-target="#navigation">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>
      </ul>
      <!-- LOGO -->
      <div class="logo-box">
        <a href="#" class="text-decoration-none logo logo-lg">
          <router-link class="logo-lg-text-light" :to="{name: dashboard}">الإداراة</router-link>
        </a>
        <a href="#" class="dropdown ml-1 text-decoration-none" v-if="superAdmin">
          <a data-toggle="dropdown" href="#" class="dropdown-toggle" id="sub-add">
            إضافة
          </a>
          <div class="dropdown-menu rounded-0">
            <router-link :to="{name: 'auctions.create'}" class="dropdown-item">
              <span>مزاد جديد</span>
            </router-link>
            <div class="dropdown-divider" v-if="superAdmin"></div>
            <router-link :to="{name: 'users.create'}" class="dropdown-item">
              <span>مستخدم جديد</span>
            </router-link>
            <div class="dropdown-divider" v-if="superAdmin"></div>
            <router-link :to="{name: 'assistants.create'}" class="dropdown-item">
              <span>مساعد جديد</span>
            </router-link>
          </div>
        </a>
      </div>
    </div>
    <div class="topbar-menu">
      <div class="collapse" id="navigation" data-parent="#top-nav">
        <ul class="navigation-menu">
          <li>
            <router-link :to="{name: 'auctions.index'}">
              <i class="fa fa-home"></i><span>الرئيسية</span>
            </router-link>
          </li>
          <!-- Auctions -->
          <li class="dropdown">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
              <label class="pointer">
                <i class="fa fa-gavel"></i>
                <span>المزادات</span>
              </label>
            </a>
            <ul class="dropdown-menu submenu min-w-250">
              <li>
                <router-link :to="{name: superAdmin ? 'admin.auctions.all' : 'admin.auctions.current'}" v-if="superAdmin">جميع المزادات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.current'}">الحالية</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.stumbled'}">المتعثرة</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.unpaid'}">بحاجة إلى تسديد</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.unpaid-floor'}">أرضيات غير مدفوعة</router-link>
              </li>
              <li class="dropdown-divider" v-if="superAdmin"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.need-invoice'}" v-if="superAdmin">إصدار فواتير</router-link>
              </li>
              <li class="dropdown-divider" v-if="superAdmin"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.need-invoice-checking'}" v-if="superAdmin">تدقيق فواتير</router-link>
              </li>
              <li class="dropdown-divider" v-if="superAdmin"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.sold-externally'}" v-if="superAdmin">مسحوبة</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.not-received'}">منتهية (غير مستلمة)</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.received'}">منتهية (مستلمة)</router-link>
              </li>
              <li class="dropdown-divider" v-if="superAdmin"></li>
              <li>
                <router-link :to="{name: 'admin.auctions.deleted'}" v-if="superAdmin">محذوفة</router-link>
              </li>
            </ul>
          </li>
          <!-- Users -->
          <li class="dropdown">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
              <label class="pointer">
                <i class="fa fa-users"></i>
                <span>المستخدمين</span>
              </label>
            </a>
            <ul class="dropdown-menu submenu">
              <li>
                <router-link :to="{name: 'users.list'}">المستخدمين</router-link>
              </li>
              <li class="dropdown-divider" v-if="superAdmin"></li>
              <li v-if="superAdmin">
                <router-link :to="{name: 'assistants.list'}">المساعدين</router-link>
              </li>
            </ul>
          </li>
          <!-- Information -->
          <li class="dropdown" v-if="superAdmin">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
              <label class="pointer">
                <i class="fas fa-list"></i>
                <span>البيانات</span>
              </label>
            </a>
            <ul class="dropdown-menu submenu">
              <li>
                <router-link :to="{name: 'companies'}">الشركات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'notes'}">الملاحظات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'insurances.list'}">شرائح التأمين</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'strategic.partners'}">شركاؤنا الاستراتيجيون</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'cars'}">السيارات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'colors'}">الوان المركبات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'invoice.config'}">اعدادات الفوتير</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'charts.index'}">احصائيات</router-link>
              </li>
            </ul>
          </li>
          <!-- Yard Management -->
          <li class="dropdown" v-if="superAdmin">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
              <label class="pointer">
                <i class="fas fa-layer-group"></i>
                <span>إدارة الساحة</span>
              </label>
            </a>
            <ul class="dropdown-menu submenu min-w-250">
              <li>
                <router-link :to="{name: 'auctions.not.valid'}">جميع المزادات</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'auctions.not.authorized'}">مزادات بحاجة الى تفويض</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'auctions.just.authorized'}">مزادات تفويض فقط</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'auctions.need.approving'}">مزادات بحاجة الى موافقة</router-link>
              </li>
            </ul>
          </li>
          <!-- Accounting -->
          <li class="dropdown" v-if="superAdmin">
            <a href="#" data-toggle="dropdown" class="dropdown-toggle">
              <label class="pointer">
                <i class="fas fa-coins"></i>
                <span>المحاسبة</span>
              </label>
            </a>
            <ul class="dropdown-menu submenu">
              <li>
                <router-link :to="{name: 'accountant'}">الفواتير</router-link>
              </li>
              <li class="dropdown-divider"></li>
              <li>
                <router-link :to="{name: 'incoming.payments'}">الدفعات الواردة</router-link>
              </li>
            </ul>
          </li>
          <li v-if="superAdmin">
            <router-link :to="{name: 'users.activation'}">
              <span>طلبات التفعيل</span>
              <span v-if="userActivationCount" class="badge badge-danger">&nbsp;{{ userActivationCount }}</span>
            </router-link>
          </li>
          <li v-if="superAdmin">
            <router-link :to="{name: 'sell.cars.list'}">
              <span>بيع المركبات</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import SettingsIcon from "@/components/SVG/SettingsIcon";
import ModalBtn from "@/components/UI/ModalBtn";
import {mapGetters} from "vuex";

export default {
  components: {ModalBtn, SettingsIcon},
  computed: {
    ...mapGetters([
      'superAdmin',
      'subAdmin',
      'dashboard',
      'canActivateUser',
      'userActivationCount',
      'canCreateAuction',
      'canUpdateAuction',
    ])
  },
  methods: {
    hideOpenedCollapse() {
      $_('.collapse.show').collapse('hide');
    }
  },
  created() {
    $_(document).on('click', '.navbar-toggle', function () {
      $_(this).toggleClass('open');
    });
    $_(document).on('click', '.dropdown-item, .collapse-item', () => {
      $_('.navbar-toggle').removeClass('open');
      this.hideOpenedCollapse();
    });
    $_(document).on('click', '.wrapper', () => {
      this.hideOpenedCollapse();
    });
  },
}
</script>

<style scoped>
@import "../../../assets/css/admin-header.css";
</style>